import { Routes } from '@angular/router';
import { documentsResolver } from './common/resolvers/documents.resolver';

export enum DocumentsRoute {
  DOCUMENTS = '/management-panel/documents',
}

export const DOCUMENTS_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    loadComponent: () =>
      import('./documents/documents.component').then(
        (m) => m.DocumentsComponent,
      ),
    resolve: { documents: documentsResolver },
  },
] as const;
