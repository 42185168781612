import { OnyxPaginated } from '@onyx/angular';
import { BASES_MOCK } from '../../../management-panel/bases/common/mocks/bases.mock';
import { DriverSettlementMethod } from '../enums/driver-settlement-method';

export const DRIVERS_MOCK: OnyxPaginated<any> = {
  items: [
    {
      uuid: '1',
      assignedBase: BASES_MOCK[0],
      driverData: {
        firstName: 'Patryk',
        lastName: 'Kawiak',
        birthDate: '2005-08-21',
        nationality: 'pl',
        privatePhone: { number: '123123123', areaCode: '+48' },
        workPhone: { number: '123123123', areaCode: '+48' },
        privateEmail: 'p.kawiak@onyxtms.com',
        peselNumber: '05282114261',
        homeAddress: {
          label: '1 1/1, 1 1, AL',
          street: 'Warszawska',
          houseNumber: '1',
          apartmentNumber: '2',
          zipCode: '550-55',
          city: 'Grodno',
          countryCode: 'al',
          coordinates: { latitude: 1, longitude: 1 },
        },
      },
      employmentConditions: {
        contractType: 'employment-contract',
        settlementMethod: 'daily-rate',
        // nullable
        dailyRate: { value: 15, currency: 'eur' },
        mileageRate: null,
        baseRate: null,
        freightPercentage: null,
        roundTripRate: null,
        contractTerm: {
          from: '2024-09-06',
          to: '2024-10-31',
          indefinite: false,
        },
        scan: ['a93ad3c0-7ce7-468a-a316-f3a9e9ae8916'],
        nipNumber: null,
        companyName: null,
      },
      skills: {
        languages: ['polish', 'english'],
        technicalSkills: ['pallet-truck', 'hds-crane'],
        trailers: ['van-reefer', 'hook-lift-reefer'],
        experienceLevel: '3',
      },
      countries: { group: 'national', whitelist: ['pl'] },
      note: 'note',
      driversLicence: {
        categories: [
          { category: 'b', expirationDate: { date: '2024-09-07' } },
          { category: 'c', expirationDate: { date: '2024-09-07' } },
        ],
        professionalQualificationsExpirationDate: null,
        number: '123',
        scan: [{}],
      },
      driverCard: {
        number: '123421234512345',
        issuingCountry: 'pl',
        expirationDate: '2023-09-30',
        scan: ['c2711e61-990e-402e-9d83-fe21b7ee1473'],
      },
      identityDocuments: {
        passport: {
          number: '',
          issuingCountry: '',
          expirationDate: null,
          scan: [],
        },
        identityDocument: {
          number: '123232323',
          issuingCountry: 'pl',
          expirationDate: '2022-02-12',
          scan: ['f42035f7-671c-436f-b20a-e2a8151b785d'],
        },
        residenceCard: {
          number: '',
          issuingCountry: 'pl',
          expirationDate: null,
          scan: [],
        },
        polishCard: {
          number: '',
          issuingCountry: 'pl',
          expirationDate: null,
          scan: [],
        },
      },
      clearCriminalRecordCertificate: {
        issuingDate: '2024-09-27',
        scan: [{}],
        certificateScan: ['f895efe8-cce7-4be9-a130-35fd0872f61e'],
      },
      visas: [
        {
          country: 'by',
          expirationDate: '2024-09-26',
          scan: ['324e1e14-6555-477a-bd80-59d5b84b8e12'],
        },
        {
          country: 'de',
          expirationDate: '2024-09-26',
          scan: ['324e1e14-6555-477a-bd80-59d5b84b8e12'],
        },
      ],
      permissions: [
        {
          permissionType: 'forklift',
          expirationDate: '2024-09-26',
          scan: ['2453dd05-c8e5-4716-a513-8edeb1a24b28'],
        },
      ],
      driversLicenceAndProfessionalQualifications: {
        driversLicenseScan: ['ea8b865a-108e-417c-ae65-33cc393cfe68'],
      },
    },
    {
      uuid: '2-c8e5',
      assignedBase: BASES_MOCK[0],
      driverData: {
        firstName: 'Adam',
        lastName: 'Kawiak',
        birthDate: '2005-08-21',
        nationality: 'pl',
        privatePhone: { number: '123123123', areaCode: '+48' },
        workPhone: { number: '123123123', areaCode: '+48' },
        privateEmail: 'p.kawiak@onyxtms.com',
        peselNumber: '05282114261',
        homeAddress: {
          label: '1 1/1, 1 1, AL',
          street: 'Warszawska',
          houseNumber: '1',
          apartmentNumber: '2',
          zipCode: '550-55',
          city: 'Grodno',
          countryCode: 'pl',
          coordinates: { latitude: 1, longitude: 1 },
        },
      },
      employmentConditions: {
        contractType: 'employment-contract',
        settlementMethod: DriverSettlementMethod.MILEAGE_RATE,
        dailyRate: null,
        mileageRate: { value: 15, currency: 'eur' },
        baseRate: { value: null, currency: 'eur' },
        freightPercentage: null,
        roundTripRate: { value: null, currency: 'eur' },
        contractTerm: {
          from: '2024-09-06',
          to: '2024-10-31',
          indefinite: false,
        },
        scan: ['a93ad3c0-7ce7-468a-a316-f3a9e9ae8916'],
        nipNumber: null,
        companyName: null,
      },
      skills: {
        languages: ['polish'],
        technicalSkills: ['pallet-truck', 'hds-crane'],
        trailers: ['van-reefer', 'hook-lift-reefer'],
        experienceLevel: '3',
      },
      countries: { group: 'national', whitelist: ['pl'] },
      note: 'note',
      driversLicence: {
        categories: [{ category: 'b', expirationDate: { date: '2024-09-07' } }],
        professionalQualificationsExpirationDate: '2024-09-07',
        number: '123',
        scan: [{}],
      },
      driverCard: {
        number: '123421234512345',
        issuingCountry: 'pl',
        expirationDate: '2024-09-30',
        scan: ['c2711e61-990e-402e-9d83-fe21b7ee1473'],
      },
      identityDocuments: {
        passport: {
          number: '',
          issuingCountry: '',
          expirationDate: null,
          scan: [],
        },
        identityDocument: {
          number: '123232323',
          issuingCountry: 'pl',
          expirationDate: '2025-02-12',
          scan: ['f42035f7-671c-436f-b20a-e2a8151b785d'],
        },
        residenceCard: {
          number: '',
          issuingCountry: 'pl',
          expirationDate: null,
          scan: [],
        },
        polishCard: {
          number: '',
          issuingCountry: 'pl',
          expirationDate: null,
          scan: [],
        },
      },
      clearCriminalRecordCertificate: {
        issuingDate: '2024-09-27',
        scan: [{}],
        certificateScan: ['f895efe8-cce7-4be9-a130-35fd0872f61e'],
      },
      visas: [
        {
          country: 'by',
          expirationDate: '2024-09-26',
          scan: ['324e1e14-6555-477a-bd80-59d5b84b8e12'],
        },
      ],
      permissions: [
        {
          permissionType: 'forklift',
          expirationDate: '2024-09-26',
          scan: ['2453dd05-c8e5-4716-a513-8edeb1a24b28'],
        },
        {
          permissionType: 'forklift',
          expirationDate: '2024-09-26',
          scan: ['2453dd05-c8e5-4716-a513-8edeb1a24b28'],
        },
      ],
      driversLicenceAndProfessionalQualifications: {
        driversLicenseScan: ['ea8b865a-108e-417c-ae65-33cc393cfe68'],
      },
    },
    {
      uuid: '3-5123450-2414',
      assignedBase: BASES_MOCK[0],
      driverData: {
        firstName: 'Patryk',
        lastName: 'Kawiak',
        birthDate: '1968-08-31',
        nationality: 'pl',
        privatePhone: { number: '523543543', areaCode: '+48' },
        workPhone: null,
        privateEmail: '',
        peselNumber: '68083193279',
        homeAddress: {
          label: 'Chełmińska 2, 82-520 Gardeja, PL',
          street: 'Chełmińska',
          houseNumber: '2',
          apartmentNumber: null,
          zipCode: '82-520',
          city: 'Gardeja',
          countryCode: 'pl',
          coordinates: { latitude: 1, longitude: 1 },
        },
      },
      employmentConditions: {
        contractType: 'b2b',
        settlementMethod: DriverSettlementMethod.BASE_PLUS_PERCENTAGE,
        dailyRate: { value: null, currency: 'pln' },
        mileageRate: null,
        baseRate: { value: 1500, currency: 'pln' },
        freightPercentage: 5,
        roundTripRate: { value: null, currency: 'pln' },
        contractTerm: {
          from: '2024-09-02',
          to: '2024-11-30',
          indefinite: false,
        },
        scan: ['74045145-fab5-47ec-8701-b3fad21039c7'],
        nipNumber: '5811978512',
        companyName: 'ONYX ALFA PROSTA SPÓŁKA AKCYJNA',
      },
      skills: {
        languages: ['english'],
        technicalSkills: ['tipper', 'hds-crane'],
        trailers: ['van-reefer', 'hook-lift-reefer'],
        experienceLevel: '4',
      },
      countries: {
        group: 'europe',
        whitelist: [
          'al',
          'ad',
          'az',
          'by',
          'ba',
          'me',
          'ge',
          'is',
          'li',
          'mk',
          'md',
          'no',
          'ru',
          'rs',
          'ch',
          'tr',
          'ua',
          'gb',
          'at',
          'be',
          'bg',
          'hr',
          'cy',
          'cz',
          'dk',
          'ee',
          'fi',
          'fr',
          'gr',
          'es',
          'nl',
          'ie',
          'lt',
          'lu',
          'lv',
          'mt',
          'de',
          'pl',
          'pt',
          'ro',
          'sk',
          'si',
          'se',
          'hu',
          'it',
        ],
      },
      note: 'note',
      driversLicence: {
        categories: [
          {
            category: 'b',
            expirationDate: { date: '2024-09-27', indefinite: false },
          },
          {
            category: 'c',
            expirationDate: { date: '2024-09-27', indefinite: false },
          },
        ],
        professionalQualificationsExpirationDate: null,
        number: '030303030',
        scan: [{}],
      },
      driverCard: {
        number: '123412341234123',
        issuingCountry: 'pl',
        expirationDate: '2024-09-30',
        scan: ['d88e38ce-3aab-47b8-908e-3132b17afb7b'],
      },
      identityDocuments: {
        passport: {
          number: '123456789',
          issuingCountry: 'pl',
          expirationDate: '2024-09-19',
          scan: ['19e8afbc-5f6e-41e6-8b9b-48332d3ae98f'],
        },
        identityDocument: {
          number: '123123123',
          issuingCountry: 'pl',
          expirationDate: '2024-09-26',
          scan: ['b486447d-7fd4-4e1e-9f72-3f4dee6d9079'],
        },
        residenceCard: {
          number: '',
          issuingCountry: 'pl',
          expirationDate: null,
          scan: [],
        },
        polishCard: {
          number: '',
          issuingCountry: 'pl',
          expirationDate: null,
          scan: [],
        },
      },
      clearCriminalRecordCertificate: {
        issuingDate: '2024-09-30',
        scan: [{}],
        certificateScan: ['73c1da81-932c-42ae-b2fe-efed88541412'],
      },
      visas: [
        {
          country: 'by',
          expirationDate: '2024-09-30',
          scan: ['26917964-ba7b-4e83-baea-8351e05b9668'],
        },
      ],
      permissions: [
        {
          permissionType: 'forklift',
          expirationDate: '2024-09-26',
          scan: ['a2551dd7-fa5c-4dc7-b0ca-9200eb6995f4'],
        },
      ],
      driversLicenceAndProfessionalQualifications: {
        driversLicenseScan: ['2e0b8420-3cd7-436b-99be-22c3aa4cc185'],
      },
    },
    {
      uuid: '4-5123450-2414',
      assignedBase: BASES_MOCK[0],
      driverData: {
        firstName: 'Patryk',
        lastName: 'Kawiak',
        birthDate: '1968-08-31',
        nationality: 'pl',
        privatePhone: { number: '523543543', areaCode: '+48' },
        workPhone: null,
        privateEmail: '',
        peselNumber: '68083193279',
        homeAddress: {
          label: 'Chełmińska 2, 82-520 Gardeja, PL',
          street: 'Chełmińska',
          houseNumber: '2',
          apartmentNumber: null,
          zipCode: '82-520',
          city: 'Gardeja',
          countryCode: 'pl',
          coordinates: { latitude: 1, longitude: 1 },
        },
      },
      employmentConditions: {
        contractType: 'b2b',
        settlementMethod: DriverSettlementMethod.ROUND_TRIP_RATE,
        dailyRate: { value: null, currency: 'pln' },
        mileageRate: null,
        baseRate: null,
        freightPercentage: 5,
        roundTripRate: { value: 1500, currency: 'pln' },
        contractTerm: {
          from: '2024-09-02',
          to: '2024-11-30',
          indefinite: false,
        },
        scan: ['74045145-fab5-47ec-8701-b3fad21039c7'],
        nipNumber: '5811978512',
        companyName: 'ONYX ALFA PROSTA SPÓŁKA AKCYJNA',
      },
      skills: {
        languages: ['english'],
        technicalSkills: ['tipper', 'hds-crane'],
        trailers: ['van-reefer', 'hook-lift-reefer'],
        experienceLevel: '4',
      },
      countries: {
        group: 'europe',
        whitelist: [
          'al',
          'ad',
          'az',
          'by',
          'ba',
          'me',
          'ge',
          'is',
          'li',
          'mk',
          'md',
          'no',
          'ru',
          'rs',
          'ch',
          'tr',
          'ua',
          'gb',
          'at',
          'be',
          'bg',
          'hr',
          'cy',
          'cz',
          'dk',
          'ee',
          'fi',
          'fr',
          'gr',
          'es',
          'nl',
          'ie',
          'lt',
          'lu',
          'lv',
          'mt',
          'de',
          'pl',
          'pt',
          'ro',
          'sk',
          'si',
          'se',
          'hu',
          'it',
        ],
      },
      note: 'note',
      driversLicence: {
        categories: [
          {
            category: 'b',
            expirationDate: { date: '2024-09-13', indefinite: false },
          },
          {
            category: 'c',
            expirationDate: { date: '2024-09-27', indefinite: false },
          },
        ],
        professionalQualificationsExpirationDate: null,
        number: '030303030',
        scan: [{}],
      },
      driverCard: {
        number: '123412341234123',
        issuingCountry: 'pl',
        expirationDate: '2024-09-30',
        scan: ['d88e38ce-3aab-47b8-908e-3132b17afb7b'],
      },
      identityDocuments: {
        passport: {
          number: '123456789',
          issuingCountry: 'pl',
          expirationDate: '2024-09-19',
          scan: ['19e8afbc-5f6e-41e6-8b9b-48332d3ae98f'],
        },
        identityDocument: {
          number: '123123123',
          issuingCountry: 'pl',
          expirationDate: '2024-09-26',
          scan: ['b486447d-7fd4-4e1e-9f72-3f4dee6d9079'],
        },
        residenceCard: {
          number: '',
          issuingCountry: 'pl',
          expirationDate: null,
          scan: [],
        },
        polishCard: {
          number: '',
          issuingCountry: 'pl',
          expirationDate: null,
          scan: [],
        },
      },
      clearCriminalRecordCertificate: {
        issuingDate: '2024-09-30',
        scan: [{}],
        certificateScan: ['73c1da81-932c-42ae-b2fe-efed88541412'],
      },
      visas: [
        {
          country: 'by',
          expirationDate: '2024-09-30',
          scan: ['26917964-ba7b-4e83-baea-8351e05b9668'],
        },
      ],
      permissions: [
        {
          permissionType: 'forklift',
          expirationDate: '2024-09-26',
          scan: ['a2551dd7-fa5c-4dc7-b0ca-9200eb6995f4'],
        },
      ],
      driversLicenceAndProfessionalQualifications: {
        driversLicenseScan: ['2e0b8420-3cd7-436b-99be-22c3aa4cc185'],
      },
    },
    {
      uuid: '122',
      driverData: {
        firstName: 'Adam',
        lastName: 'Wątel',
        birthDate: '1977-04-15',
        nationality: 'pl',
        privatePhone: { number: '123123123', areaCode: '+48' },
        workPhone: { number: '123123123', areaCode: '+48' },
        privateEmail: 'a.w@ww.pl',
        peselNumber: '77041546169',
        homeAddress: {
          label: '1 1/1, 1 1, BE',
          street: '1',
          houseNumber: '1',
          apartmentNumber: '1',
          zipCode: '1',
          city: '1',
          countryCode: 'be',
          coordinates: { latitude: 1, longitude: 1 },
        },
      },
      employmentConditions: {
        contractType: 'mandate-contract',
        settlementMethod: 'base-plus-percentage',
        dailyRate: { value: null, currency: 'pln' },
        mileageRate: { value: null, currency: 'pln' },
        baseRate: { value: 1200, currency: 'pln' },
        freightPercentage: 40,
        roundTripRate: { value: null, currency: 'pln' },
        contractTerm: {
          from: '2024-09-19',
          to: '2024-09-30',
          indefinite: false,
        },
        scan: ['d54239a4-3e5a-42cb-a1b2-ee7ef40b7b54'],
        nipNumber: null,
        companyName: null,
      },
      skills: {
        languages: ['french'],
        technicalSkills: ['forklift', 'dump-container', 'pallet-truck'],
        trailers: ['flatbed-reefer', 'hook-lift-reefer'],
        experienceLevel: '3',
      },
      countries: { group: 'national', whitelist: ['pl'] },
      note: '123',
      driversLicence: {
        categories: [
          { category: 'b', expirationDate: { date: null, indefinite: true } },
        ],
        professionalQualificationsExpirationDate: null,
        number: '123123312123123',
        scan: [{}],
      },
      driverCard: {
        number: '123123123123123',
        issuingCountry: 'pl',
        expirationDate: '2024-09-26',
        scan: ['ec0c30bc-6301-47ae-b8d5-8d5b115e64d6'],
      },
      identityDocuments: {
        passport: {
          number: '',
          issuingCountry: '',
          expirationDate: null,
          scan: [],
        },
        identityDocument: {
          number: '123123123',
          issuingCountry: 'pl',
          expirationDate: '2024-09-29',
          scan: ['209608d9-c4a5-47ae-914e-448889712119'],
        },
        residenceCard: {
          number: '',
          issuingCountry: 'pl',
          expirationDate: null,
          scan: [],
        },
        polishCard: {
          number: '',
          issuingCountry: 'pl',
          expirationDate: null,
          scan: [],
        },
      },
      clearCriminalRecordCertificate: {
        issuingDate: '2024-09-26',
        scan: [{}],
        certificateScan: ['840a66c0-a34d-4a89-8d18-329f6f87d1ab'],
      },
      visas: [],
      permissions: [],
      driversLicenceAndProfessionalQualifications: {
        driversLicenseScan: ['592929b5-db9c-4262-ba64-c4f596969d9f'],
      },
    },
  ],
  limit: 1,
  page: 10,
  totalItems: 1,
};
