import { Routes } from '@angular/router';

export enum EmployeesRoute {
  EMPLOYEES_LIST = `/management-panel/employees`,
}

export const EMPLOYEES_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    loadComponent: () =>
      import('./employees-list/employees-list.component').then(
        (m) => m.EmployeesListComponent,
      ),
  },
] as const;
