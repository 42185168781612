import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { AuthRoute } from '../../auth.routes';
import { AuthRouterStateKey } from '../enums/auth-router-state-key';

export const registerEmailGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
) => {
  const router = inject(Router);

  const navigationState = router.getCurrentNavigation()?.extras?.state;
  const companyName = navigationState?.[AuthRouterStateKey.COMPANY_NAME];
  const email = navigationState?.[AuthRouterStateKey.EMAIL];
  const password = navigationState?.[AuthRouterStateKey.PASSWORD];

  const isToken = !!route.queryParamMap.get('token');
  const areUserCredentials = Boolean(companyName && email && password);

  if (isToken || areUserCredentials) {
    return true;
  }

  router.navigateByUrl(AuthRoute.LOGIN, {
    state: {
      [AuthRouterStateKey.IS_INVALID_ACTIVATE_TOKEN]: true,
    },
  });
  return false;
};
