import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { groupBy, isObject } from 'lodash';
import { forkJoin, map, tap } from 'rxjs';
import { AuthService } from '../../../../../auth/common/services/auth.service';
import {
  BusinessDocumentKey,
  BusinessDocuments,
  BusinessProposalDocuments,
} from '../../../../../common/interfaces/documents/business-documents';
import { DocumentsService } from '../services/documents.service';

export const documentsResolver: ResolveFn<unknown> = () => {
  const documentsService = inject(DocumentsService);
  const authService = inject(AuthService);

  const mapProposal = (
    documents: BusinessDocuments,
  ): BusinessProposalDocuments => {
    const type = Object.entries(documents)
      .filter(([_, document]) => isObject(document))
      .find(([_, document]) => document.file)?.[0] as BusinessDocumentKey;

    return {
      status: documents.status,
      type,
      [type]: documents[type],
    };
  };

  return forkJoin({
    proposals: documentsService
      .getProposalsDocuments()
      .pipe(map((documents) => documents.items.map(mapProposal))),
    documents: documentsService.getDocuments().pipe(
      tap((documents) => authService.updateDocuments(documents)),
      map((documents) =>
        Object.entries(documents)
          .filter(([_, document]) => isObject(document))
          .map(([type, _]) => ({
            status: documents.status,
            type,
            [type]: documents[type as BusinessDocumentKey],
          })),
      ),
    ),
  }).pipe(
    map((data) => groupBy([...data.proposals, ...data.documents], 'status')),
  );
};
