import { Routes } from '@angular/router';
import { OnyxFormMode } from '@onyx/angular';
import { addPointOfInterestResolver } from './common/resolvers/add-point-of-interest.resolver';
import { editPointOfInterestResolver } from './common/resolvers/edit-point-of-interest.resolver';

export enum PointsOfInterestRoute {
  POINTS_OF_INTEREST_LIST = '/management-panel/points-of-interest',
  ADD_POINT_OF_INTEREST = '/management-panel/points-of-interest/add',
  EDIT_POINT_OF_INTEREST = '/management-panel/points-of-interest/:uuid/edit',
}

export const POINTS_OF_INTEREST_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    loadComponent: () =>
      import(
        './points-of-interest-list/points-of-interest-list.component'
      ).then((m) => m.PointsOfInterestListComponent),
  },
  {
    path: 'add',
    loadComponent: () =>
      import(
        './points-of-interest-form/points-of-interest-form.component'
      ).then((m) => m.PointsOfInterestFormComponent),
    data: { mode: OnyxFormMode.ADD },
    resolve: { point: addPointOfInterestResolver },
  },
  {
    path: ':uuid/edit',
    loadComponent: () =>
      import(
        './points-of-interest-form/points-of-interest-form.component'
      ).then((m) => m.PointsOfInterestFormComponent),
    data: { mode: OnyxFormMode.EDIT },
    resolve: { point: editPointOfInterestResolver },
  },
] as const;
