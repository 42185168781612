import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { OnyxToastService, OnyxToastType } from '@onyx/angular';
import { captureException } from '@sentry/angular-ivy';
import { catchError, map, of } from 'rxjs';
import { DriverForm } from '../../driver-form/driver-form.component';
import { DriversRoute } from '../../drivers.routes';
import { DriversService } from '../services/drivers.service';

export const editDriverResolver: ResolveFn<DriverForm | null> = (
  route: ActivatedRouteSnapshot,
) => {
  const driverService = inject(DriversService);
  const toastService = inject(OnyxToastService);
  const router = inject(Router);

  const uuid = route.paramMap.get('uuid')!;

  return driverService.getDriver(uuid).pipe(
    map(
      // TEMP: Replace if type ready
      (driver: any): DriverForm => ({
        driverData: driver.driverData,
        clearCriminalRecordCertificate: driver.clearCriminalRecordCertificate,
        countries: driver.countries,
        driverCard: driver.driverCard,
        driversLicence: driver.driversLicence,
        employmentConditions: driver.employmentConditions,
        identityDocuments: driver.identityDocuments,
        note: driver.note,
        permissions: driver.permissions,
        skills: driver.skills,
        visas: driver.visas,
      }),
    ),
    catchError((error: HttpErrorResponse) => {
      if (error.status === HttpStatusCode.BadRequest) {
        toastService.showError('drivers.driverForm.driverNotFound', {
          keepOnNavigation: true,
        });
      } else {
        toastService.showCustom(OnyxToastType.SERVER_ERROR, {
          keepOnNavigation: true,
        });
        if (error.status !== HttpStatusCode.InternalServerError) {
          captureException(error);
        }
      }

      router.navigateByUrl(DriversRoute.DRIVERS_LIST);
      return of(null);
    }),
  );
};
