import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OnyxPaginated } from '@onyx/angular';
import { map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { BatchFileUpload } from '../../../../common/interfaces/utilities/batch-file-upload';
import { ApiService } from '../../../../common/services/api.service';
import { StorageService } from '../../../../common/services/storage.service';
import { DriverFormDto } from '../../driver-form/driver-form.component';
import { DriverDto } from '../interfaces/driver.dto';
import { DRIVERS_MOCK } from '../mocks/drivers-mock';

@Injectable({
  providedIn: 'root',
})
export class DriversService extends ApiService {
  private _reload$ = new Subject<void>();
  public get reload$() {
    return this._reload$.asObservable();
  }

  constructor(
    protected override http: HttpClient,
    private storageService: StorageService,
  ) {
    super(http);
  }

  public listDrivers(): Observable<OnyxPaginated<DriverDto>> {
    // return this.get('/drivers');
    return of(DRIVERS_MOCK);
  }

  public getDriver(_uuid: string): Observable<DriverDto> {
    // return this.get(`/drivers/${_uuid}`);
    return of(DRIVERS_MOCK.items[0]);
  }

  public addDriver(dto: DriverFormDto): Observable<void> {
    return this.uploadFiles(dto).pipe(
      switchMap((dto) => this.post<void>('/drivers', dto)),
      tap(() => this._reload$.next()),
    );
  }

  public editDriver(uuid: string, dto: DriverFormDto): Observable<void> {
    return this.uploadFiles(dto).pipe(
      switchMap((dto) => this.put<void>(`/drivers/${uuid}`, dto)),
      tap(() => this._reload$.next()),
    );
  }

  public archiveDriver(uuid: string): Observable<void> {
    return this.post<void>(`/drivers/${uuid}/archive`, undefined).pipe(
      tap(() => this._reload$.next()),
    );
  }

  private uploadFiles(dto: DriverFormDto): Observable<DriverFormDto> {
    return of(dto).pipe(
      map((dto): BatchFileUpload[] => [
        {
          path: 'employmentConditions.scan',
          files: dto.employmentConditions.scan,
        },
        ...(dto.driverCard
          ? [
              {
                path: 'driverCard.scan',
                files: dto.driverCard.scan,
              },
            ]
          : []),
        {
          path: 'driversLicenceAndProfessionalQualifications.driversLicenseScan',
          files: dto.driversLicence.scan,
        },
        {
          path: 'identityDocuments.passport.scan',
          files: dto.identityDocuments.passport.scan,
        },
        {
          path: 'identityDocuments.identityDocument.scan',
          files: dto.identityDocuments.identityDocument.scan,
        },
        {
          path: 'identityDocuments.residenceCard.scan',
          files: dto.identityDocuments.residenceCard.scan,
        },
        {
          path: 'identityDocuments.polishCard.scan',
          files: dto.identityDocuments.polishCard.scan,
        },
        {
          path: 'clearCriminalRecordCertificate.certificateScan',
          files: dto.clearCriminalRecordCertificate.scan,
        },
        ...dto.visas.map((visa, index) => ({
          path: `visas[${index}].scan`,
          files: visa.scan,
        })),
        ...dto.permissions.map((permission, index) => ({
          path: `permissions[${index}].scan`,
          files: permission.scan,
        })),
      ]),
      switchMap((data) => this.storageService.uploadBatch(data)),
      map((data) => this.storageService.mergeBatch(dto, data)),
    );
  }
}
