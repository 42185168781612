import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { AuthRoute } from '../../auth.routes';
import { AuthRouterStateKey } from '../enums/auth-router-state-key';

export const resetPasswordGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
) => {
  const router = inject(Router);

  if (route.queryParamMap.get('token')) return true;

  router.navigateByUrl(AuthRoute.LOGIN, {
    state: {
      [AuthRouterStateKey.IS_INVALID_RESET_TOKEN]: true,
    },
  });
  return false;
};
