import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { AuthRoute } from '../../auth.routes';
import { AuthRouterStateKey } from '../enums/auth-router-state-key';

export const joinGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);

  if (
    route.queryParamMap.get('token') &&
    route.queryParamMap.get('email') &&
    route.queryParamMap.get('companyId')
  ) {
    return true;
  }

  router.navigateByUrl(AuthRoute.LOGIN, {
    state: {
      [AuthRouterStateKey.IS_INVALID_INVITE]: true,
    },
  });
  return false;
};
