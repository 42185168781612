<onyx-modal
  type="center"
  size="s"
  color="gray"
  [heading]="
    I18N +
      '.heading.' +
      (formStep() === FormStep.CATEGORY ? 'selectCategory' : category())
      | translate
  "
  [close$]="close$"
  (keydown.enter)="disabled() ? null : continue()"
  (closeModal)="dialogRef.close()"
>
  <div class="items" [formGroup]="form">
    @let source =
      formStep() === FormStep.CATEGORY ? (categories$ | async) : types();
    @for (item of source; track item.value) {
      <!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
      <!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
      <div
        class="item"
        [ngClass]="{
          active:
            item.value ===
            (formStep() === FormStep.CATEGORY ? category() : type()),
        }"
        (click)="
          (formStep() === FormStep.CATEGORY
            ? categoryControl
            : typeControl
          ).setValue(item.value)
        "
      >
        @switch (formStep()) {
          @case (FormStep.CATEGORY) {
            <onyx-icon [name]="$any(item.value)" [size]="24"></onyx-icon>
          }
          @case (FormStep.TYPE) {
            <onyx-custom-icon
              source="assets/icons/fleet/placeholder.svg"
              [size]="24"
            ></onyx-custom-icon>
          }
        }

        <p class="f-regular-1">{{ item.name }}</p>

        <onyx-radio-button
          [formControlName]="
            formStep() === FormStep.CATEGORY ? 'category' : 'type'
          "
          [option]="item.value"
          size="m"
        ></onyx-radio-button>
      </div>
    }
  </div>

  <ng-container bottomRightOptions>
    <onyx-button type="outlined" color="black" (click)="back()">
      {{
        (formStep() === FormStep.CATEGORY ? "buttons.cancel" : "buttons.back")
          | translate
      }}
    </onyx-button>

    <onyx-button
      type="primary"
      color="blue"
      [spinner]="loading()"
      [disabled]="disabled()"
      (click)="disabled() ? null : continue()"
    >
      {{ "buttons.continue" | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>
