import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { PartialDeep } from 'type-fest';
import { CacheService } from '../../../../../common/services/cache.service';
import { PointsOfInterestForm } from '../../points-of-interest-form/points-of-interest-form.component';
import { PointsOfInterestStorageKey } from '../enums/points-of-interest-storage-key';

export const addPointOfInterestResolver: ResolveFn<
  PartialDeep<PointsOfInterestForm>
> = () => {
  const cacheService = inject(CacheService);

  const cacheKey = PointsOfInterestStorageKey.ADD_POINT_OF_INTEREST_FORM;
  const cache = cacheService.get<PartialDeep<PointsOfInterestForm>>(cacheKey, {
    keepOnNavigation: true,
  });

  if (cache) return cache;

  return {};
};
