import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneDeep, isArray, set } from 'lodash';
import { forkJoin, map, Observable, of, switchMap, zip } from 'rxjs';
import { BatchFileUpload } from '../interfaces/utilities/batch-file-upload';
import { BatchFileUploadResponse } from '../interfaces/utilities/batch-file-upload-response';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService extends ApiService {
  constructor(protected override http: HttpClient) {
    super(http);
  }

  public getFile(uuid: string): Observable<File> {
    return this.get(`/storage/${uuid}`, {
      responseType: 'blob' as 'json',
    });
  }

  public uploadFile(file: File): Observable<string> {
    const data = new FormData();
    data.set('file', file);
    data.set('fileType', 'file-scan'); // TEMP

    return this.post(`/storage`, data).pipe(
      map((response: any) => response.storageUuid),
    );
  }

  public uploadBatch(
    data: BatchFileUpload[],
  ): Observable<BatchFileUploadResponse[]> {
    return of(data).pipe(
      map((data) =>
        data.filter(
          (upload) =>
            !!upload.files && (!isArray(upload.files) || upload.files.length),
        ),
      ),
      map((data) =>
        data.flatMap((upload) => {
          const files = isArray(upload.files) ? upload.files : [upload.files];
          return files.map((file) =>
            zip(
              of(upload),
              file instanceof File ? this.uploadFile(file) : of(file),
            ),
          );
        }),
      ),
      switchMap((requests$) =>
        requests$.length ? forkJoin(requests$) : of([]),
      ),
      map((responses) =>
        responses.reduce((result, [upload, uuid]) => {
          const item = result.find((r) => r.path === upload.path);
          if (!uuid) {
            throw new Error('Invalid batch upload response');
          } else if (!item) {
            result.push({
              path: upload.path,
              uuids: isArray(upload.files) ? [uuid] : uuid,
            });
          } else if (isArray(item.uuids)) {
            item.uuids = [...item.uuids, uuid];
          }
          return result;
        }, [] as BatchFileUploadResponse[]),
      ),
    );
  }

  public mergeBatch<T extends object>(
    object: T,
    data: BatchFileUploadResponse[],
  ): T {
    const copy = cloneDeep(object);
    for (const item of data) {
      set(copy, item.path, item.uuids);
    }
    return copy;
  }

  public createBlob(file: string): Observable<File> {
    const name = file.split('/').pop() ?? '';
    return this.http
      .get(file, { responseType: 'blob' })
      .pipe(map((blob) => new File([blob], name, { type: blob.type })));
  }

  public openBlobUrl(file: File): void {
    window.open(URL.createObjectURL(file));
  }
}
