import { Routes } from '@angular/router';

export enum IntegrationsRoute {
  INTEGRATIONS_LIST = '/management-panel/integrations',
}

export const INTEGRATIONS_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    loadComponent: () =>
      import('./integrations/integrations.component').then(
        (m) => m.IntegrationsComponent,
      ),
  },
] as const;
