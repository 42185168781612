import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { PartialDeep } from 'type-fest';
import { CacheService } from '../../../../../common/services/cache.service';
import { BaseForm } from '../../base-form/base-form.component';
import { BasesStorageKey } from '../enums/bases-storage-key';

export const addBaseResolver: ResolveFn<PartialDeep<BaseForm>> = () => {
  const cacheService = inject(CacheService);

  const cacheKey = BasesStorageKey.ADD_BASE_FORM;
  const cache = cacheService.get<PartialDeep<BaseForm>>(cacheKey, {
    keepOnNavigation: true,
  });

  if (cache) return cache;

  return {};
};
