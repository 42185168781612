import { Dialog } from '@angular/cdk/dialog';
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FleetRoute } from '../../fleet.routes';
import { AddFleetModalComponent } from '../components/add-fleet-modal/add-fleet-modal.component';
import { FleetRouterStateKey } from '../enums/fleet-router-state-key';

export const addFleetGuard: CanActivateFn = () => {
  const router = inject(Router);
  const dialog = inject(Dialog);

  const state = router.getCurrentNavigation()?.extras?.state;
  const category = state?.[FleetRouterStateKey.CATEGORY];
  const type = state?.[FleetRouterStateKey.TYPE];

  if (category && type !== undefined) return true;

  dialog.open(AddFleetModalComponent);
  return router.parseUrl(FleetRoute.FLEET_LIST);
};
