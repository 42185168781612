import { DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  FormHelper,
  OnyxButtonComponent,
  OnyxDropdownComponent,
  OnyxModalComponent,
  OnyxPhone,
  OnyxPhoneNumberInputComponent,
  OnyxTextFieldComponent,
  OnyxToastService,
  OnyxToastType,
  onyxPhoneValidator,
} from '@onyx/angular';
import { Subject } from 'rxjs';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { DictionariesService } from '../../../../../common/services/dictionaries.service';
import { PreferencesService } from '../../../../../common/services/preferences.service';
import { DriversRoute } from '../../../drivers.routes';
import { DriversRouterStateKey } from '../../enums/drivers-router-state-key';

@Component({
  selector: 'app-add-driver-modal',
  standalone: true,
  imports: [
    OnyxModalComponent,
    TranslateModule,
    ReactiveFormsModule,
    OnyxDropdownComponent,
    OnyxTextFieldComponent,
    OnyxPhoneNumberInputComponent,
    OnyxButtonComponent,
    AsyncPipe,
  ],
  templateUrl: './add-driver-modal.component.html',
  styleUrl: './add-driver-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddDriverModalComponent {
  protected readonly I18N = 'drivers.addDriverModal';

  protected readonly countries$ = this.dictionariesService.getDictionary(
    DictionaryCode.COUNTRY,
  );

  protected form = this.buildForm();
  protected close$ = new Subject<void>();

  constructor(
    protected dialogRef: DialogRef,
    private fb: NonNullableFormBuilder,
    private dictionariesService: DictionariesService,
    private toastService: OnyxToastService,
    private router: Router,
    private preferencesService: PreferencesService,
  ) {}

  protected next(): void {
    if (this.form.invalid) {
      FormHelper.submit(this.form);
      this.toastService.showCustom(OnyxToastType.INVALID_DATA);
      return;
    }

    const { privatePhone, nationality } = this.form.value;
    this.router.navigateByUrl(DriversRoute.ADD_DRIVER, {
      state: {
        [DriversRouterStateKey.PRIVATE_PHONE]: privatePhone,
        [DriversRouterStateKey.NATIONALITY]: nationality,
      },
    });

    this.close$.next();
  }

  private buildForm() {
    return this.fb.group({
      privatePhone: this.fb.control<OnyxPhone | null>(null, [
        Validators.required,
        onyxPhoneValidator,
      ]),
      nationality: this.fb.control<string | null>(
        this.preferencesService.defaultCountry(),
        [Validators.required],
      ),
    });
  }
}
