import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FleetValidation } from '../interfaces/validation/fleet-validation';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ValidationService extends ApiService {
  constructor(protected override http: HttpClient) {
    super(http);
  }

  public getFleetValidation(category: string): Observable<FleetValidation> {
    const type = category.includes('trailer') ? 'trailer' : 'vehicle';
    return this.get('/validation/fleet', { params: { type } });
  }
}
