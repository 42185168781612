import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { PartialDeep } from 'type-fest';
import { CompanyGusData } from '../../../../../common/interfaces/data-providers/company-gus-data';
import { CacheService } from '../../../../../common/services/cache.service';
import { ContractorForm } from '../../contractor-form/contractor-form.component';
import { ContractorsRouterStateKey } from '../enums/contractors-router-state-key';
import { ContractorsStorageKey } from '../enums/contractors-storage-key';

export const addContractorResolver: ResolveFn<
  PartialDeep<ContractorForm> | CompanyGusData
> = () => {
  const router = inject(Router);
  const cacheService = inject(CacheService);

  const state = router.getCurrentNavigation()?.extras?.state;
  const country = state?.[ContractorsRouterStateKey.COUNTRY];
  const vatId = state?.[ContractorsRouterStateKey.VAT_ID];

  const cacheKey = `${ContractorsStorageKey.ADD_CONTRACTOR_FORM}-${country}-${vatId}`;
  const cache = cacheService.get<PartialDeep<ContractorForm>>(cacheKey, {
    keepOnNavigation: true,
  });

  if (cache) return cache;

  return {
    companyProfile: {
      country,
      vatId,
    },
  };
};
