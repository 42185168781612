import { Base } from '../interfaces/base';

export const BASES_MOCK: Base[] = [
  {
    assignedDrivers: [
      {
        uuid: '35fd0872f61e',
        driverData: {
          firstName: 'Patryk',
          lastName: 'Kawiak',
          birthDate: '2005-08-21',
          nationality: 'pl',
          privatePhone: {
            number: '123123123',
            areaCode: '+48',
          },
          workPhone: {
            number: '123123123',
            areaCode: '+48',
          },
          privateEmail: 'p.kawiak@onyxtms.com',
          peselNumber: '05282114261',
          homeAddress: {
            label: '1 1/1, 1 1, AL',
            street: 'Warszawska',
            houseNumber: '1',
            apartmentNumber: '2',
            zipCode: '550-55',
            city: 'Grodno',
            countryCode: 'al',
            coordinates: {
              latitude: 1,
              longitude: 1,
            },
          },
        },
        employmentConditions: {
          contractType: 'employment-contract',
          settlementMethod: 'daily-rate',
          dailyRate: {
            value: 15,
            currency: 'eur',
          },
          mileageRate: {
            value: null,
            currency: 'eur',
          },
          baseRate: {
            value: null,
            currency: 'eur',
          },
          freightPercentage: null,
          roundTripRate: {
            value: null,
            currency: 'eur',
          },
          contractTerm: {
            from: '2024-09-06',
            to: '2024-10-31',
            indefinite: false,
          },
          scan: ['a93ad3c0-7ce7-468a-a316-f3a9e9ae8916'],
          nipNumber: null,
          companyName: null,
        },
        skills: {
          languages: ['polish'],
          technicalSkills: ['pallet-truck', 'hds-crane'],
          trailers: ['van-reefer', 'hook-lift-reefer'],
          experienceLevel: '3',
        },
        countries: {
          group: 'national',
          whitelist: ['pl'],
        },
        note: 'note',
        driversLicence: {
          categories: [
            {
              category: 'b',
              expirationDate: {
                date: '2024-09-07',
              },
            },
          ],
          professionalQualificationsExpirationDate: null,
          number: '123',
          scan: [{}],
        },
        driverCard: {
          number: '123421234512345',
          issuingCountry: 'pl',
          expirationDate: '2024-09-30',
          scan: ['c2711e61-990e-402e-9d83-fe21b7ee1473'],
        },
        identityDocuments: {
          passport: {
            number: '',
            issuingCountry: '',
            expirationDate: null,
            scan: [],
          },
          identityDocument: {
            number: '123232323',
            issuingCountry: 'pl',
            expirationDate: '2025-02-12',
            scan: ['f42035f7-671c-436f-b20a-e2a8151b785d'],
          },
          residenceCard: {
            number: '',
            issuingCountry: 'pl',
            expirationDate: null,
            scan: [],
          },
          polishCard: {
            number: '',
            issuingCountry: 'pl',
            expirationDate: null,
            scan: [],
          },
        },
        clearCriminalRecordCertificate: {
          issuingDate: '2024-09-27',
          scan: [{}],
          certificateScan: ['f895efe8-cce7-4be9-a130-35fd0872f61e'],
        },
        visas: [
          {
            country: 'by',
            expirationDate: '2024-09-26',
            scan: ['324e1e14-6555-477a-bd80-59d5b84b8e12'],
          },
        ],
        permissions: [
          {
            permissionType: 'forklift',
            expirationDate: '2024-09-26',
            scan: ['2453dd05-c8e5-4716-a513-8edeb1a24b28'],
          },
        ],
        driversLicenceAndProfessionalQualifications: {
          driversLicenseScan: ['ea8b865a-108e-417c-ae65-33cc393cfe68'],
        },
      },
    ],
    assignedVehicles: [
      {
        uuid: '86769b17-1d30-497b-8bfe-b146bb2acbaf',
        generalInformation: {
          category: 'semi-truck',
          type: null,
          registrationNumber: 'WA 23445',
          vinNumber: '2T1BURHE1EC134224',
          manufactureYear: 2002,
          firstRegistrationDate: '2003-06-03',
          make: 'AEC',
          model: 'string',
          sideNumber: 'string',
          maxSpeed: 1,
          initialMileage: null,
          state: 'active',
          planningMode: 'auto',
        },
        fuelTankCapacity: {
          fuelType: 'on',
          mainTank: 1,
          adBlueTank: 1,
          additionalTank: 1,
          generatorFuelTank: null,
        },
        telematics: null,
        vehicleMeasurements: {
          length: 1,
          width: 1,
          height: 1,
          axleCount: 2,
          curbWeight: 1,
        },
        cargoSpace: {
          loadCapacity: null,
          volumeCapacity: null,
          chambersNumber: null,
          tankCertificates: null,
          dischargeTypes: null,
          europalletsNumber: null,
          length: null,
          width: null,
          height: null,
        },
        registrationCertificate: {
          technicalInspectionExpiryDate: '2024-08-28',
          registrationCertificateScanFileUuid: [
            '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            '324c1f62-1b55-4330-8c08-3a6d91863f9f',
          ],
        },
        co2Emissions: {
          emissionStandard: 'euro-1',
          emissionCertificate: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        },
        thirdPartyLiabilityInsurance: {
          type: 'third-party-liability',
          contractFirstPageScan: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          insuranceNumber: 'string',
          expiryDate: '2024-08-28',
          annualCost: {
            date: '2024-02-20',
            value: 5599,
            currency: 'pln',
          },
          insuranceSum: {
            date: '2024-02-20',
            value: 5599,
            currency: 'pln',
          },
        },
        comprehensiveInsurance: {
          type: 'comprehensive',
          contractFirstPageScan: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          insuranceNumber: 'string',
          expiryDate: '2024-08-28',
          annualCost: {
            date: '2024-02-20',
            value: 5599,
            currency: 'pln',
          },
          insuranceSum: {
            date: '2024-02-20',
            value: 5599,
            currency: 'pln',
          },
        },
        licensesAndPermits: {
          communityLicenseAbstractNumber: 'string',
          permitAbstractNumber: 'string',
        },
        additionalParameters: {
          adrClasses: null,
          isLowDeck: true,
          beltsNumber: null,
          hooksNumber: null,
          hasSanitaryEpidemiologicalStation: null,
          hasHaccp: null,
          hasHdsCrane: null,
          matsNumber: null,
          coilWellLength: null,
          hasCustomsSecurity: null,
          trailerSize: null,
          semiTrailerSize: null,
          hasLoadingsRamps: null,
          temperatureRange: null,
          hasTailLift: null,
          hasDumpContainer: null,
          hasForklift: null,
          otherParameters: ['string'],
        },
        location: {
          latitude: null,
          longitude: null,
        },
        ownershipType: {
          type: 'own',
          vehicleValue: {
            date: '2024-02-20',
            value: 5599,
            currency: 'pln',
          },
          depreciationPeriod: {
            from: '2024-04-20',
            to: '2024-06-20',
          },
          buyout: null,
          installment: null,
          selfDeposit: null,
          firstAndLastInstallmentDate: null,
          margin: null,
          interestRateType: null,
          isForSale: true,
        },
        allowedCountries: {
          group: 'national',
          whitelist: [],
        },
        tachograph: {
          model: 'analog',
          make: 'stoneridge',
          nextLegalizationDate: '2024-08-28',
        },
        status: 'available-for-assignment',
        trailerUuid: null,
        drivers: {
          primaryDriver: null,
          secondaryDriver: null,
        },
        businessUuid: 'f46e9c48-6dcc-463c-8e01-748bc4f26c58',
      },
      {
        uuid: '46769b17-1d30-497b-8bfe-b146bb2acbaf',
        generalInformation: {
          category: 'semi-truck',
          type: null,
          registrationNumber: 'WA 23445',
          vinNumber: '2T1BURHE1EC134224',
          manufactureYear: 2002,
          firstRegistrationDate: '2003-06-03',
          make: 'AEC',
          model: 'string',
          sideNumber: 'string',
          maxSpeed: 1,
          initialMileage: null,
          state: 'active',
          planningMode: 'auto',
        },
        fuelTankCapacity: {
          fuelType: 'on',
          mainTank: 1,
          adBlueTank: 1,
          additionalTank: 1,
          generatorFuelTank: null,
        },
        telematics: null,
        vehicleMeasurements: {
          length: 1,
          width: 1,
          height: 1,
          axleCount: 2,
          curbWeight: 1,
        },
        cargoSpace: {
          loadCapacity: null,
          volumeCapacity: null,
          chambersNumber: null,
          tankCertificates: null,
          dischargeTypes: null,
          europalletsNumber: null,
          length: null,
          width: null,
          height: null,
        },
        registrationCertificate: {
          technicalInspectionExpiryDate: '2024-08-28',
          registrationCertificateScanFileUuid: [
            '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            '324c1f62-1b55-4330-8c08-3a6d91863f9f',
          ],
        },
        co2Emissions: {
          emissionStandard: 'euro-1',
          emissionCertificate: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        },
        thirdPartyLiabilityInsurance: {
          type: 'third-party-liability',
          contractFirstPageScan: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          insuranceNumber: 'string',
          expiryDate: '2024-08-28',
          annualCost: {
            date: '2024-02-20',
            value: 5599,
            currency: 'pln',
          },
          insuranceSum: {
            date: '2024-02-20',
            value: 5599,
            currency: 'pln',
          },
        },
        comprehensiveInsurance: {
          type: 'comprehensive',
          contractFirstPageScan: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          insuranceNumber: 'string',
          expiryDate: '2024-08-28',
          annualCost: {
            date: '2024-02-20',
            value: 5599,
            currency: 'pln',
          },
          insuranceSum: {
            date: '2024-02-20',
            value: 5599,
            currency: 'pln',
          },
        },
        licensesAndPermits: {
          communityLicenseAbstractNumber: 'string',
          permitAbstractNumber: 'string',
        },
        additionalParameters: {
          adrClasses: null,
          isLowDeck: true,
          beltsNumber: null,
          hooksNumber: null,
          hasSanitaryEpidemiologicalStation: null,
          hasHaccp: null,
          hasHdsCrane: null,
          matsNumber: null,
          coilWellLength: null,
          hasCustomsSecurity: null,
          trailerSize: null,
          semiTrailerSize: null,
          hasLoadingsRamps: null,
          temperatureRange: null,
          hasTailLift: null,
          hasDumpContainer: null,
          hasForklift: null,
          otherParameters: ['string'],
        },
        location: {
          latitude: null,
          longitude: null,
        },
        ownershipType: {
          type: 'own',
          vehicleValue: {
            date: '2024-02-20',
            value: 5599,
            currency: 'pln',
          },
          depreciationPeriod: {
            from: '2024-04-20',
            to: '2024-06-20',
          },
          buyout: null,
          installment: null,
          selfDeposit: null,
          firstAndLastInstallmentDate: null,
          margin: null,
          interestRateType: null,
          isForSale: true,
        },
        allowedCountries: {
          group: 'national',
          whitelist: [],
        },
        tachograph: {
          model: 'analog',
          make: 'stoneridge',
          nextLegalizationDate: '2024-08-28',
        },
        status: 'available-for-assignment',
        trailerUuid: null,
        drivers: {
          primaryDriver: null,
          secondaryDriver: null,
        },
        businessUuid: 'f46e9c48-6dcc-463c-8e01-748bc4f26c58',
      },
    ],
    uuid: '52145-f1244f-491249',
    address: {
      label: 'string',
      street: 'Grudziądzka',
      houseNumber: '8A',
      apartmentNumber: '10',
      zipCode: '82-500',
      city: 'Kwidzyn',
      countryCode: 'pl',
      coordinates: {
        latitude: 53.7291961,
        longitude: 18.9339541,
      },
    },
    phone: { areaCode: '+48', number: '123342234' },
    name: 'Monachium-1',
    businessHours: {
      weekday: {
        from: '10:00:00',
        to: '15:00:00',
      },
      saturday: {
        from: '10:00:00',
        to: '15:00:00',
      },
      sunday: {
        from: '10:00:00',
        to: '15:00:00',
      },
    },
  },
  {
    assignedDrivers: [
      {
        uuid: '35fd0872f61e',
        driverData: {
          firstName: 'Patryk',
          lastName: 'Kawiak',
          birthDate: '2005-08-21',
          nationality: 'pl',
          privatePhone: {
            number: '123123123',
            areaCode: '+48',
          },
          workPhone: {
            number: '123123123',
            areaCode: '+48',
          },
          privateEmail: 'p.kawiak@onyxtms.com',
          peselNumber: '05282114261',
          homeAddress: {
            label: '1 1/1, 1 1, AL',
            street: 'Warszawska',
            houseNumber: '1',
            apartmentNumber: '2',
            zipCode: '550-55',
            city: 'Grodno',
            countryCode: 'al',
            coordinates: {
              latitude: 1,
              longitude: 1,
            },
          },
        },
        employmentConditions: {
          contractType: 'employment-contract',
          settlementMethod: 'daily-rate',
          dailyRate: {
            value: 15,
            currency: 'eur',
          },
          mileageRate: {
            value: null,
            currency: 'eur',
          },
          baseRate: {
            value: null,
            currency: 'eur',
          },
          freightPercentage: null,
          roundTripRate: {
            value: null,
            currency: 'eur',
          },
          contractTerm: {
            from: '2024-09-06',
            to: '2024-10-31',
            indefinite: false,
          },
          scan: ['a93ad3c0-7ce7-468a-a316-f3a9e9ae8916'],
          nipNumber: null,
          companyName: null,
        },
        skills: {
          languages: ['polish'],
          technicalSkills: ['pallet-truck', 'hds-crane'],
          trailers: ['van-reefer', 'hook-lift-reefer'],
          experienceLevel: '3',
        },
        countries: {
          group: 'national',
          whitelist: ['pl'],
        },
        note: 'note',
        driversLicence: {
          categories: [
            {
              category: 'b',
              expirationDate: {
                date: '2024-09-07',
              },
            },
          ],
          professionalQualificationsExpirationDate: null,
          number: '123',
          scan: [{}],
        },
        driverCard: {
          number: '123421234512345',
          issuingCountry: 'pl',
          expirationDate: '2024-09-30',
          scan: ['c2711e61-990e-402e-9d83-fe21b7ee1473'],
        },
        identityDocuments: {
          passport: {
            number: '',
            issuingCountry: '',
            expirationDate: null,
            scan: [],
          },
          identityDocument: {
            number: '123232323',
            issuingCountry: 'pl',
            expirationDate: '2025-02-12',
            scan: ['f42035f7-671c-436f-b20a-e2a8151b785d'],
          },
          residenceCard: {
            number: '',
            issuingCountry: 'pl',
            expirationDate: null,
            scan: [],
          },
          polishCard: {
            number: '',
            issuingCountry: 'pl',
            expirationDate: null,
            scan: [],
          },
        },
        clearCriminalRecordCertificate: {
          issuingDate: '2024-09-27',
          scan: [{}],
          certificateScan: ['f895efe8-cce7-4be9-a130-35fd0872f61e'],
        },
        visas: [
          {
            country: 'by',
            expirationDate: '2024-09-26',
            scan: ['324e1e14-6555-477a-bd80-59d5b84b8e12'],
          },
        ],
        permissions: [
          {
            permissionType: 'forklift',
            expirationDate: '2024-09-26',
            scan: ['2453dd05-c8e5-4716-a513-8edeb1a24b28'],
          },
        ],
        driversLicenceAndProfessionalQualifications: {
          driversLicenseScan: ['ea8b865a-108e-417c-ae65-33cc393cfe68'],
        },
      },
    ],
    assignedVehicles: [
      {
        uuid: '86769b17-1d30-497b-8bfe-b146bb2acbaf',
        generalInformation: {
          category: 'semi-truck',
          type: null,
          registrationNumber: 'WA 23445',
          vinNumber: '2T1BURHE1EC134224',
          manufactureYear: 2002,
          firstRegistrationDate: '2003-06-03',
          make: 'AEC',
          model: 'string',
          sideNumber: 'string',
          maxSpeed: 1,
          initialMileage: null,
          state: 'active',
          planningMode: 'auto',
        },
        fuelTankCapacity: {
          fuelType: 'on',
          mainTank: 1,
          adBlueTank: 1,
          additionalTank: 1,
          generatorFuelTank: null,
        },
        telematics: null,
        vehicleMeasurements: {
          length: 1,
          width: 1,
          height: 1,
          axleCount: 2,
          curbWeight: 1,
        },
        cargoSpace: {
          loadCapacity: null,
          volumeCapacity: null,
          chambersNumber: null,
          tankCertificates: null,
          dischargeTypes: null,
          europalletsNumber: null,
          length: null,
          width: null,
          height: null,
        },
        registrationCertificate: {
          technicalInspectionExpiryDate: '2024-08-28',
          registrationCertificateScanFileUuid: [
            '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            '324c1f62-1b55-4330-8c08-3a6d91863f9f',
          ],
        },
        co2Emissions: {
          emissionStandard: 'euro-1',
          emissionCertificate: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        },
        thirdPartyLiabilityInsurance: {
          type: 'third-party-liability',
          contractFirstPageScan: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          insuranceNumber: 'string',
          expiryDate: '2024-08-28',
          annualCost: {
            date: '2024-02-20',
            value: 5599,
            currency: 'pln',
          },
          insuranceSum: {
            date: '2024-02-20',
            value: 5599,
            currency: 'pln',
          },
        },
        comprehensiveInsurance: {
          type: 'comprehensive',
          contractFirstPageScan: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          insuranceNumber: 'string',
          expiryDate: '2024-08-28',
          annualCost: {
            date: '2024-02-20',
            value: 5599,
            currency: 'pln',
          },
          insuranceSum: {
            date: '2024-02-20',
            value: 5599,
            currency: 'pln',
          },
        },
        licensesAndPermits: {
          communityLicenseAbstractNumber: 'string',
          permitAbstractNumber: 'string',
        },
        additionalParameters: {
          adrClasses: null,
          isLowDeck: true,
          beltsNumber: null,
          hooksNumber: null,
          hasSanitaryEpidemiologicalStation: null,
          hasHaccp: null,
          hasHdsCrane: null,
          matsNumber: null,
          coilWellLength: null,
          hasCustomsSecurity: null,
          trailerSize: null,
          semiTrailerSize: null,
          hasLoadingsRamps: null,
          temperatureRange: null,
          hasTailLift: null,
          hasDumpContainer: null,
          hasForklift: null,
          otherParameters: ['string'],
        },
        location: {
          latitude: null,
          longitude: null,
        },
        ownershipType: {
          type: 'own',
          vehicleValue: {
            date: '2024-02-20',
            value: 5599,
            currency: 'pln',
          },
          depreciationPeriod: {
            from: '2024-04-20',
            to: '2024-06-20',
          },
          buyout: null,
          installment: null,
          selfDeposit: null,
          firstAndLastInstallmentDate: null,
          margin: null,
          interestRateType: null,
          isForSale: true,
        },
        allowedCountries: {
          group: 'national',
          whitelist: [],
        },
        tachograph: {
          model: 'analog',
          make: 'stoneridge',
          nextLegalizationDate: '2024-08-28',
        },
        status: 'available-for-assignment',
        trailerUuid: null,
        drivers: {
          primaryDriver: null,
          secondaryDriver: null,
        },
        businessUuid: 'f46e9c48-6dcc-463c-8e01-748bc4f26c58',
      },
    ],
    uuid: '52145-f1244f-491249',
    address: {
      label: 'string',
      street: 'Grudziądzka',
      houseNumber: '8A',
      apartmentNumber: '10',
      zipCode: '82-500',
      city: 'Kwidzyn',
      countryCode: 'pl',
      coordinates: {
        latitude: 53.7291961,
        longitude: 18.9339541,
      },
    },
    phone: { areaCode: '+48', number: '123342234' },
    name: 'Monachium-1',
    businessHours: {
      weekday: {
        from: '10:00:00',
        to: '15:00:00',
      },
      saturday: {
        from: '10:00:00',
        to: '15:00:00',
      },
      sunday: {
        from: '10:00:00',
        to: '15:00:00',
      },
    },
  },
  {
    assignedDrivers: [
      {
        uuid: '35fd0872f61e',
        driverData: {
          firstName: 'Patryk',
          lastName: 'Kawiak',
          birthDate: '2005-08-21',
          nationality: 'pl',
          privatePhone: {
            number: '123123123',
            areaCode: '+48',
          },
          workPhone: {
            number: '123123123',
            areaCode: '+48',
          },
          privateEmail: 'p.kawiak@onyxtms.com',
          peselNumber: '05282114261',
          homeAddress: {
            label: '1 1/1, 1 1, AL',
            street: 'Warszawska',
            houseNumber: '1',
            apartmentNumber: '2',
            zipCode: '550-55',
            city: 'Grodno',
            countryCode: 'al',
            coordinates: {
              latitude: 1,
              longitude: 1,
            },
          },
        },
        employmentConditions: {
          contractType: 'employment-contract',
          settlementMethod: 'daily-rate',
          dailyRate: {
            value: 15,
            currency: 'eur',
          },
          mileageRate: {
            value: null,
            currency: 'eur',
          },
          baseRate: {
            value: null,
            currency: 'eur',
          },
          freightPercentage: null,
          roundTripRate: {
            value: null,
            currency: 'eur',
          },
          contractTerm: {
            from: '2024-09-06',
            to: '2024-10-31',
            indefinite: false,
          },
          scan: ['a93ad3c0-7ce7-468a-a316-f3a9e9ae8916'],
          nipNumber: null,
          companyName: null,
        },
        skills: {
          languages: ['polish'],
          technicalSkills: ['pallet-truck', 'hds-crane'],
          trailers: ['van-reefer', 'hook-lift-reefer'],
          experienceLevel: '3',
        },
        countries: {
          group: 'national',
          whitelist: ['pl'],
        },
        note: 'note',
        driversLicence: {
          categories: [
            {
              category: 'b',
              expirationDate: {
                date: '2024-09-07',
              },
            },
          ],
          professionalQualificationsExpirationDate: null,
          number: '123',
          scan: [{}],
        },
        driverCard: {
          number: '123421234512345',
          issuingCountry: 'pl',
          expirationDate: '2024-09-30',
          scan: ['c2711e61-990e-402e-9d83-fe21b7ee1473'],
        },
        identityDocuments: {
          passport: {
            number: '',
            issuingCountry: '',
            expirationDate: null,
            scan: [],
          },
          identityDocument: {
            number: '123232323',
            issuingCountry: 'pl',
            expirationDate: '2025-02-12',
            scan: ['f42035f7-671c-436f-b20a-e2a8151b785d'],
          },
          residenceCard: {
            number: '',
            issuingCountry: 'pl',
            expirationDate: null,
            scan: [],
          },
          polishCard: {
            number: '',
            issuingCountry: 'pl',
            expirationDate: null,
            scan: [],
          },
        },
        clearCriminalRecordCertificate: {
          issuingDate: '2024-09-27',
          scan: [{}],
          certificateScan: ['f895efe8-cce7-4be9-a130-35fd0872f61e'],
        },
        visas: [
          {
            country: 'by',
            expirationDate: '2024-09-26',
            scan: ['324e1e14-6555-477a-bd80-59d5b84b8e12'],
          },
        ],
        permissions: [
          {
            permissionType: 'forklift',
            expirationDate: '2024-09-26',
            scan: ['2453dd05-c8e5-4716-a513-8edeb1a24b28'],
          },
        ],
        driversLicenceAndProfessionalQualifications: {
          driversLicenseScan: ['ea8b865a-108e-417c-ae65-33cc393cfe68'],
        },
      },
    ],
    assignedVehicles: [
      {
        uuid: '86769b17-1d30-497b-8bfe-b146bb2acbaf',
        generalInformation: {
          category: 'semi-truck',
          type: null,
          registrationNumber: 'WA 23445',
          vinNumber: '2T1BURHE1EC134224',
          manufactureYear: 2002,
          firstRegistrationDate: '2003-06-03',
          make: 'AEC',
          model: 'string',
          sideNumber: 'string',
          maxSpeed: 1,
          initialMileage: null,
          state: 'active',
          planningMode: 'auto',
        },
        fuelTankCapacity: {
          fuelType: 'on',
          mainTank: 1,
          adBlueTank: 1,
          additionalTank: 1,
          generatorFuelTank: null,
        },
        telematics: null,
        vehicleMeasurements: {
          length: 1,
          width: 1,
          height: 1,
          axleCount: 2,
          curbWeight: 1,
        },
        cargoSpace: {
          loadCapacity: null,
          volumeCapacity: null,
          chambersNumber: null,
          tankCertificates: null,
          dischargeTypes: null,
          europalletsNumber: null,
          length: null,
          width: null,
          height: null,
        },
        registrationCertificate: {
          technicalInspectionExpiryDate: '2024-08-28',
          registrationCertificateScanFileUuid: [
            '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            '324c1f62-1b55-4330-8c08-3a6d91863f9f',
          ],
        },
        co2Emissions: {
          emissionStandard: 'euro-1',
          emissionCertificate: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        },
        thirdPartyLiabilityInsurance: {
          type: 'third-party-liability',
          contractFirstPageScan: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          insuranceNumber: 'string',
          expiryDate: '2024-08-28',
          annualCost: {
            date: '2024-02-20',
            value: 5599,
            currency: 'pln',
          },
          insuranceSum: {
            date: '2024-02-20',
            value: 5599,
            currency: 'pln',
          },
        },
        comprehensiveInsurance: {
          type: 'comprehensive',
          contractFirstPageScan: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          insuranceNumber: 'string',
          expiryDate: '2024-08-28',
          annualCost: {
            date: '2024-02-20',
            value: 5599,
            currency: 'pln',
          },
          insuranceSum: {
            date: '2024-02-20',
            value: 5599,
            currency: 'pln',
          },
        },
        licensesAndPermits: {
          communityLicenseAbstractNumber: 'string',
          permitAbstractNumber: 'string',
        },
        additionalParameters: {
          adrClasses: null,
          isLowDeck: true,
          beltsNumber: null,
          hooksNumber: null,
          hasSanitaryEpidemiologicalStation: null,
          hasHaccp: null,
          hasHdsCrane: null,
          matsNumber: null,
          coilWellLength: null,
          hasCustomsSecurity: null,
          trailerSize: null,
          semiTrailerSize: null,
          hasLoadingsRamps: null,
          temperatureRange: null,
          hasTailLift: null,
          hasDumpContainer: null,
          hasForklift: null,
          otherParameters: ['string'],
        },
        location: {
          latitude: null,
          longitude: null,
        },
        ownershipType: {
          type: 'own',
          vehicleValue: {
            date: '2024-02-20',
            value: 5599,
            currency: 'pln',
          },
          depreciationPeriod: {
            from: '2024-04-20',
            to: '2024-06-20',
          },
          buyout: null,
          installment: null,
          selfDeposit: null,
          firstAndLastInstallmentDate: null,
          margin: null,
          interestRateType: null,
          isForSale: true,
        },
        allowedCountries: {
          group: 'national',
          whitelist: [],
        },
        tachograph: {
          model: 'analog',
          make: 'stoneridge',
          nextLegalizationDate: '2024-08-28',
        },
        status: 'available-for-assignment',
        trailerUuid: null,
        drivers: {
          primaryDriver: null,
          secondaryDriver: null,
        },
        businessUuid: 'f46e9c48-6dcc-463c-8e01-748bc4f26c58',
      },
    ],
    uuid: '52145-f1244f-491249',
    address: {
      label: 'string',
      street: 'Grudziądzka',
      houseNumber: '8A',
      apartmentNumber: '10',
      zipCode: '82-500',
      city: 'Kwidzyn',
      countryCode: 'pl',
      coordinates: {
        latitude: 53.7291961,
        longitude: 18.9339541,
      },
    },
    phone: { areaCode: '+48', number: '123342234' },
    name: 'Monachium-1',
    businessHours: {
      weekday: {
        from: '10:00:00',
        to: '15:00:00',
      },
      saturday: {
        from: '10:00:00',
        to: '15:00:00',
      },
      sunday: {
        from: '10:00:00',
        to: '15:00:00',
      },
    },
  },
  {
    assignedDrivers: [
      {
        uuid: '35fd0872f61e',
        driverData: {
          firstName: 'Patryk',
          lastName: 'Kawiak',
          birthDate: '2005-08-21',
          nationality: 'pl',
          privatePhone: {
            number: '123123123',
            areaCode: '+48',
          },
          workPhone: {
            number: '123123123',
            areaCode: '+48',
          },
          privateEmail: 'p.kawiak@onyxtms.com',
          peselNumber: '05282114261',
          homeAddress: {
            label: '1 1/1, 1 1, AL',
            street: 'Warszawska',
            houseNumber: '1',
            apartmentNumber: '2',
            zipCode: '550-55',
            city: 'Grodno',
            countryCode: 'al',
            coordinates: {
              latitude: 1,
              longitude: 1,
            },
          },
        },
        employmentConditions: {
          contractType: 'employment-contract',
          settlementMethod: 'daily-rate',
          dailyRate: {
            value: 15,
            currency: 'eur',
          },
          mileageRate: {
            value: null,
            currency: 'eur',
          },
          baseRate: {
            value: null,
            currency: 'eur',
          },
          freightPercentage: null,
          roundTripRate: {
            value: null,
            currency: 'eur',
          },
          contractTerm: {
            from: '2024-09-06',
            to: '2024-10-31',
            indefinite: false,
          },
          scan: ['a93ad3c0-7ce7-468a-a316-f3a9e9ae8916'],
          nipNumber: null,
          companyName: null,
        },
        skills: {
          languages: ['polish'],
          technicalSkills: ['pallet-truck', 'hds-crane'],
          trailers: ['van-reefer', 'hook-lift-reefer'],
          experienceLevel: '3',
        },
        countries: {
          group: 'national',
          whitelist: ['pl'],
        },
        note: 'note',
        driversLicence: {
          categories: [
            {
              category: 'b',
              expirationDate: {
                date: '2024-09-07',
              },
            },
          ],
          professionalQualificationsExpirationDate: null,
          number: '123',
          scan: [{}],
        },
        driverCard: {
          number: '123421234512345',
          issuingCountry: 'pl',
          expirationDate: '2024-09-30',
          scan: ['c2711e61-990e-402e-9d83-fe21b7ee1473'],
        },
        identityDocuments: {
          passport: {
            number: '',
            issuingCountry: '',
            expirationDate: null,
            scan: [],
          },
          identityDocument: {
            number: '123232323',
            issuingCountry: 'pl',
            expirationDate: '2025-02-12',
            scan: ['f42035f7-671c-436f-b20a-e2a8151b785d'],
          },
          residenceCard: {
            number: '',
            issuingCountry: 'pl',
            expirationDate: null,
            scan: [],
          },
          polishCard: {
            number: '',
            issuingCountry: 'pl',
            expirationDate: null,
            scan: [],
          },
        },
        clearCriminalRecordCertificate: {
          issuingDate: '2024-09-27',
          scan: [{}],
          certificateScan: ['f895efe8-cce7-4be9-a130-35fd0872f61e'],
        },
        visas: [
          {
            country: 'by',
            expirationDate: '2024-09-26',
            scan: ['324e1e14-6555-477a-bd80-59d5b84b8e12'],
          },
        ],
        permissions: [
          {
            permissionType: 'forklift',
            expirationDate: '2024-09-26',
            scan: ['2453dd05-c8e5-4716-a513-8edeb1a24b28'],
          },
        ],
        driversLicenceAndProfessionalQualifications: {
          driversLicenseScan: ['ea8b865a-108e-417c-ae65-33cc393cfe68'],
        },
      },
    ],
    assignedVehicles: [
      {
        uuid: '86769b17-1d30-497b-8bfe-b146bb2acbaf',
        generalInformation: {
          category: 'semi-truck',
          type: null,
          registrationNumber: 'WA 23445',
          vinNumber: '2T1BURHE1EC134224',
          manufactureYear: 2002,
          firstRegistrationDate: '2003-06-03',
          make: 'AEC',
          model: 'string',
          sideNumber: 'string',
          maxSpeed: 1,
          initialMileage: null,
          state: 'active',
          planningMode: 'auto',
        },
        fuelTankCapacity: {
          fuelType: 'on',
          mainTank: 1,
          adBlueTank: 1,
          additionalTank: 1,
          generatorFuelTank: null,
        },
        telematics: null,
        vehicleMeasurements: {
          length: 1,
          width: 1,
          height: 1,
          axleCount: 2,
          curbWeight: 1,
        },
        cargoSpace: {
          loadCapacity: null,
          volumeCapacity: null,
          chambersNumber: null,
          tankCertificates: null,
          dischargeTypes: null,
          europalletsNumber: null,
          length: null,
          width: null,
          height: null,
        },
        registrationCertificate: {
          technicalInspectionExpiryDate: '2024-08-28',
          registrationCertificateScanFileUuid: [
            '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            '324c1f62-1b55-4330-8c08-3a6d91863f9f',
          ],
        },
        co2Emissions: {
          emissionStandard: 'euro-1',
          emissionCertificate: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        },
        thirdPartyLiabilityInsurance: {
          type: 'third-party-liability',
          contractFirstPageScan: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          insuranceNumber: 'string',
          expiryDate: '2024-08-28',
          annualCost: {
            date: '2024-02-20',
            value: 5599,
            currency: 'pln',
          },
          insuranceSum: {
            date: '2024-02-20',
            value: 5599,
            currency: 'pln',
          },
        },
        comprehensiveInsurance: {
          type: 'comprehensive',
          contractFirstPageScan: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          insuranceNumber: 'string',
          expiryDate: '2024-08-28',
          annualCost: {
            date: '2024-02-20',
            value: 5599,
            currency: 'pln',
          },
          insuranceSum: {
            date: '2024-02-20',
            value: 5599,
            currency: 'pln',
          },
        },
        licensesAndPermits: {
          communityLicenseAbstractNumber: 'string',
          permitAbstractNumber: 'string',
        },
        additionalParameters: {
          adrClasses: null,
          isLowDeck: true,
          beltsNumber: null,
          hooksNumber: null,
          hasSanitaryEpidemiologicalStation: null,
          hasHaccp: null,
          hasHdsCrane: null,
          matsNumber: null,
          coilWellLength: null,
          hasCustomsSecurity: null,
          trailerSize: null,
          semiTrailerSize: null,
          hasLoadingsRamps: null,
          temperatureRange: null,
          hasTailLift: null,
          hasDumpContainer: null,
          hasForklift: null,
          otherParameters: ['string'],
        },
        location: {
          latitude: null,
          longitude: null,
        },
        ownershipType: {
          type: 'own',
          vehicleValue: {
            date: '2024-02-20',
            value: 5599,
            currency: 'pln',
          },
          depreciationPeriod: {
            from: '2024-04-20',
            to: '2024-06-20',
          },
          buyout: null,
          installment: null,
          selfDeposit: null,
          firstAndLastInstallmentDate: null,
          margin: null,
          interestRateType: null,
          isForSale: true,
        },
        allowedCountries: {
          group: 'national',
          whitelist: [],
        },
        tachograph: {
          model: 'analog',
          make: 'stoneridge',
          nextLegalizationDate: '2024-08-28',
        },
        status: 'available-for-assignment',
        trailerUuid: null,
        drivers: {
          primaryDriver: null,
          secondaryDriver: null,
        },
        businessUuid: 'f46e9c48-6dcc-463c-8e01-748bc4f26c58',
      },
    ],
    uuid: '52145-f1244f-491249',
    address: {
      label: 'string',
      street: 'Grudziądzka',
      houseNumber: '8A',
      apartmentNumber: '10',
      zipCode: '82-500',
      city: 'Kwidzyn',
      countryCode: 'pl',
      coordinates: {
        latitude: 53.7291961,
        longitude: 18.9339541,
      },
    },
    phone: { areaCode: '+48', number: '123342234' },
    name: 'Monachium-1',
    businessHours: {
      weekday: {
        from: '10:00:00',
        to: '15:00:00',
      },
      saturday: {
        from: '10:00:00',
        to: '15:00:00',
      },
      sunday: {
        from: '10:00:00',
        to: '15:00:00',
      },
    },
  },
];
