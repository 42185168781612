import { Routes } from '@angular/router';

export enum DictionariesRoute {
  DICTIONARIES = `/management-panel/dictionaries`,
}

export const DICTIONARIES_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    loadComponent: () =>
      import('./dictionaries/dictionaries.component').then(
        (m) => m.DictionariesComponent,
      ),
  },
];
