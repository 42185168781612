import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthRoute } from '../../../auth/auth.routes';
import { BusinessDocumentsStatus } from '../../../auth/common/enums/business/business-documents-status';
import { AuthService } from '../../../auth/common/services/auth.service';
import { DashboardRoute } from '../../dashboard.routes';
import { DashboardPermission } from '../enums/dashboard-permission';

export const dashboardGuard = (
  permission?: DashboardPermission,
): CanActivateFn => {
  return () => {
    const authService = inject(AuthService);
    const router = inject(Router);

    const business = authService.business();

    if (business?.documents?.status !== BusinessDocumentsStatus.VERIFIED) {
      return router.createUrlTree([AuthRoute.REGISTER_DOCUMENTS]);
    }

    if (permission && !authService.hasPermission(permission)) {
      return router.createUrlTree([DashboardRoute.DASHBOARD]);
    }

    return true;
  };
};
