import { Routes } from '@angular/router';
import { DashboardRoute } from '../dashboard.routes';

export enum SettingsRoute {
  MY_DATA = `${DashboardRoute.SETTINGS}/my-data`,
  PASSWORD = `${DashboardRoute.SETTINGS}/password`,
}

export const SETTINGS_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    redirectTo: 'my-data',
  },
  {
    path: 'my-data',
    loadComponent: () =>
      import('./settings-my-data/settings-my-data.component').then(
        (m) => m.SettingsMyDataComponent,
      ),
  },
  {
    path: 'password',
    loadComponent: () =>
      import('./settings-password/settings-password.component').then(
        (m) => m.SettingsPasswordComponent,
      ),
  },
] as const;
