import { Routes } from '@angular/router';
import { OnyxFormMode } from '@onyx/angular';
import { addDriverGuard } from './common/guards/add-driver.guard';
import { addDriverResolver } from './common/resolvers/add-driver.resolver';
import { editDriverResolver } from './common/resolvers/edit-driver.resolver';

export enum DriversRoute {
  DRIVERS_LIST = '/drivers',
  ADD_DRIVER = '/drivers/add',
  DRIVER_CARD = '/drivers/:uuid',
  EDIT_DRIVER = '/drivers/:uuid/edit',
}

export const DRIVERS_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    loadComponent: () =>
      import('./drivers-list/drivers-list.component').then(
        (m) => m.DriversListComponent,
      ),
  },
  {
    path: 'add',
    loadComponent: () =>
      import('./driver-form/driver-form.component').then(
        (m) => m.DriverFormComponent,
      ),
    canActivate: [addDriverGuard],
    data: { mode: OnyxFormMode.ADD },
    resolve: { driver: addDriverResolver },
  },
  {
    path: ':uuid/edit',
    loadComponent: () =>
      import('./driver-form/driver-form.component').then(
        (m) => m.DriverFormComponent,
      ),
    data: { mode: OnyxFormMode.EDIT },
    resolve: { driver: editDriverResolver },
  },
] as const;
