import {
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { OnyxToastService } from '@onyx/angular';
import { catchError, throwError } from 'rxjs';
import { GLOBAL_API_NAMESPACE } from '../../../../config/config';
import { environment } from '../../../../config/environment';
import { Business } from '../../../dashboard/management-panel/company-data/common/interfaces/business';
import { BusinessDocumentsStatus } from '../enums/business/business-documents-status';
import { AuthService } from '../services/auth.service';

export const AUTH_INTERCEPTOR_NAMESPACE_HEADER = 'X-Auth-Interceptor-Namespace';

export const authInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const authService = inject(AuthService);
  const toastService = inject(OnyxToastService);

  const token = authService.token();
  if (token != null) request = addAuthorizationHeader(request, token);

  const business = authService.business();
  const companyName = authService.companyName();
  request = addNamespace(request, business, companyName);

  return next(request).pipe(
    catchError((error) => {
      if (error.status === HttpStatusCode.Unauthorized && authService.token()) {
        authService.logout();
        toastService.showError('auth.sessionExpired', {
          keepOnNavigation: true,
        });
      }
      return throwError(() => error);
    }),
  );
};

const addAuthorizationHeader = (
  request: HttpRequest<unknown>,
  token: string,
): HttpRequest<unknown> => {
  return request.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const addNamespace = (
  request: HttpRequest<unknown>,
  business: Business | null,
  companyName: string | null,
): HttpRequest<unknown> => {
  if (!request.url.startsWith(environment.apiUrl)) return request;

  const url = new URL(request.url);
  const namespace = request.headers.get(AUTH_INTERCEPTOR_NAMESPACE_HEADER);

  if (namespace != null) {
    url.host = `${namespace}.${url.host}`;
  } else if (
    business?.documents?.status === BusinessDocumentsStatus.VERIFIED &&
    companyName != null
  ) {
    url.host = `${companyName}.${url.host}`;
  } else {
    url.host = `${GLOBAL_API_NAMESPACE}.${url.host}`;
  }

  const headers = request.headers.delete(AUTH_INTERCEPTOR_NAMESPACE_HEADER);
  return request.clone({ url: url.href, headers });
};
