import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../config/environment';

@Injectable({
  providedIn: 'root',
})
export abstract class ApiService {
  private readonly API_URL = environment.apiUrl;

  protected constructor(protected http: HttpClient) {}

  protected get<T>(
    url: string,
    options?: Parameters<HttpClient['get']>['1'],
  ): Observable<T> {
    return this.http.get(this.API_URL + url, options) as Observable<T>;
  }

  protected post<T>(
    url: string,
    body: any,
    options?: Parameters<HttpClient['post']>['2'],
  ): Observable<T> {
    return this.http.post(this.API_URL + url, body, options) as Observable<T>;
  }

  protected put<T>(
    url: string,
    body: any,
    options?: Parameters<HttpClient['put']>['2'],
  ): Observable<T> {
    return this.http.put(this.API_URL + url, body, options) as Observable<T>;
  }

  protected delete<T>(
    url: string,
    options?: Parameters<HttpClient['delete']>['1'],
  ): Observable<T> {
    return this.http.delete(this.API_URL + url, options) as Observable<T>;
  }
}
