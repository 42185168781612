import { Routes } from '@angular/router';
import { OnyxFormMode } from '@onyx/angular';
import { addContractorGuard } from './common/guards/add-contractor.guard';
import { addContractorResolver } from './common/resolvers/add-contractor.resolver';
import { editContractorResolver } from './common/resolvers/edit-contractor.resolver';

export enum ContractorsRoute {
  CONTRACTORS_LIST = '/management-panel/contractors',
  ADD_CONTRACTOR = '/management-panel/contractors/add',
  EDIT_CONTRACTOR = '/management-panel/contractors/:uuid/edit',
}

export const CONTRACTORS_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    loadComponent: () =>
      import('./contractors-list/contractors-list.component').then(
        (m) => m.ContractorsListComponent,
      ),
  },
  {
    path: 'add',
    loadComponent: () =>
      import('./contractor-form/contractor-form.component').then(
        (m) => m.ContractorFormComponent,
      ),
    canActivate: [addContractorGuard],
    data: { mode: OnyxFormMode.ADD },
    resolve: { contractor: addContractorResolver },
  },
  {
    path: ':uuid/edit',
    loadComponent: () =>
      import('./contractor-form/contractor-form.component').then(
        (m) => m.ContractorFormComponent,
      ),
    data: { mode: OnyxFormMode.EDIT },
    resolve: { contractor: editContractorResolver },
  },
] as const;
