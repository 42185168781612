import { Routes } from '@angular/router';

export enum CompanyDataRoute {
  COMPANY_DATA = '/management-panel/company-data',
}

export const COMPANY_DATA_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    loadComponent: () =>
      import('./company-data/company-data.component').then(
        (m) => m.CompanyDataComponent,
      ),
  },
] as const;
