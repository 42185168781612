import { Routes } from '@angular/router';

export enum EngineConfigRoute {
  ENGINE_CONFIG = '/management-panel/engine-config',
}

export const ENGINE_CONFIG_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    loadComponent: () =>
      import('./engine-config/engine-config.component').then(
        (m) => m.EngineConfigComponent,
      ),
  },
] as const;
