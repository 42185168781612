import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { PartialDeep } from 'type-fest';
import { CacheService } from '../../../../common/services/cache.service';
import { DriverForm } from '../../driver-form/driver-form.component';
import { DriversRouterStateKey } from '../enums/drivers-router-state-key';
import { DriversStorageKey } from '../enums/drivers-storage-key';

export const addDriverResolver: ResolveFn<PartialDeep<DriverForm>> = () => {
  const router = inject(Router);
  const cacheService = inject(CacheService);

  const state = router.getCurrentNavigation()?.extras?.state;
  const privatePhone = state?.[DriversRouterStateKey.PRIVATE_PHONE];
  const nationality = state?.[DriversRouterStateKey.NATIONALITY];

  const cacheKey = `${DriversStorageKey.ADD_DRIVER_FORM}-${nationality}-${privatePhone.areaCode}-${privatePhone.number}`;
  const cache = cacheService.get<PartialDeep<DriverForm>>(cacheKey, {
    keepOnNavigation: true,
  });
  if (cache) return cache;

  return {
    driverData: {
      nationality,
      privatePhone,
    },
  };
};
