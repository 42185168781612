import { Routes } from '@angular/router';
import { OnyxFormMode } from '@onyx/angular';
import { addFleetGuard } from './common/guards/add-fleet.guard';
import { addFleetResolver } from './common/resolvers/add-fleet.resolver';
import { editFleetResolver } from './common/resolvers/edit-fleet.resolver';

export enum FleetRoute {
  FLEET_LIST = '/fleet',
  ADD_FLEET = '/fleet/add',
  FLEET_CARD = '/fleet/:uuid',
  EDIT_FLEET = '/fleet/:uuid/edit',
}

export const FLEET_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    loadComponent: () =>
      import('./fleet-list/fleet-list.component').then(
        (m) => m.FleetListComponent,
      ),
  },
  {
    path: 'add',
    loadComponent: () =>
      import('./fleet-form/fleet-form.component').then(
        (m) => m.FleetFormComponent,
      ),
    canActivate: [addFleetGuard],
    data: { mode: OnyxFormMode.ADD },
    resolve: { fleet: addFleetResolver },
  },
  {
    path: ':uuid/edit',
    loadComponent: () =>
      import('./fleet-form/fleet-form.component').then(
        (m) => m.FleetFormComponent,
      ),
    data: { mode: OnyxFormMode.EDIT },
    resolve: { fleet: editFleetResolver },
  },
] as const;
