export enum AuthStorageKey {
  COMPANY_NAME = 'companyName',
  TOKEN = 'token',
  USER = 'user',
  BUSINESS = 'business',
  PERMISSIONS = 'permissions',
}

export const AUTH_SENSITIVE_KEYS = [
  AuthStorageKey.TOKEN,
  AuthStorageKey.USER,
  AuthStorageKey.BUSINESS,
  AuthStorageKey.PERMISSIONS,
] as const;
