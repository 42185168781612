import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OnyxPaginated } from '@onyx/angular';
import { map, Observable, switchMap } from 'rxjs';
import { BusinessDocumentType } from '../../../../../auth/common/enums/business/business-document-type';
import { AuthService } from '../../../../../auth/common/services/auth.service';
import { KrsDocumentForm } from '../../../../../auth/register-documents/register-documents-krs/register-documents-krs.component';
import { OcDocumentForm } from '../../../../../auth/register-documents/register-documents-oc/register-documents-oc.component';
import { RegonDocumentForm } from '../../../../../auth/register-documents/register-documents-regon/register-documents-regon.component';
import { TransportLicenseDocumentForm } from '../../../../../auth/register-documents/register-documents-transport-license/register-documents-transport-license.component';
import { VatDocumentForm } from '../../../../../auth/register-documents/register-documents-vat/register-documents-vat.component';
import {
  BusinessDocuments,
  BusinessDocumentValue,
} from '../../../../../common/interfaces/documents/business-documents';
import { ApiService } from '../../../../../common/services/api.service';
import { StorageService } from '../../../../../common/services/storage.service';

interface DocumentsForm {
  [BusinessDocumentType.CEIDG_ABSTRACT]: KrsDocumentForm;
  [BusinessDocumentType.REGON_ENTRY]: RegonDocumentForm;
  [BusinessDocumentType.THIRD_PARTY_LIABILITY_INSURANCE_POLICY]: OcDocumentForm;
  [BusinessDocumentType.TRANSPORT_LICENSE]: TransportLicenseDocumentForm;
  [BusinessDocumentType.VAT_STATUS_CONFIRMATION]: VatDocumentForm;
}

@Injectable({
  providedIn: 'root',
})
export class DocumentsService extends ApiService {
  constructor(
    protected override http: HttpClient,
    private storageService: StorageService,
    private authService: AuthService,
  ) {
    super(http);
  }

  public getDocuments(): Observable<BusinessDocuments> {
    return this.get('/business/documents');
  }

  public getProposalsDocuments(): Observable<OnyxPaginated<BusinessDocuments>> {
    return this.get('/business/documents/proposals');
  }

  public updateDocument<T extends BusinessDocumentType>(
    type: T,
    form: DocumentsForm[T],
    location: 'app' | 'auth' = 'app',
  ) {
    let file: string | File | null;
    if ('ceidgAbstract' in form) {
      file = form.ceidgAbstract.file;
    } else if ('thirdPartyLiabilityInsurancePolicy' in form) {
      file = form.thirdPartyLiabilityInsurancePolicy.file;
    } else if ('regonEntry' in form) {
      file = form.regonEntry.file;
    } else if ('transportLicense' in form) {
      file = form.transportLicense.file;
    } else {
      file = form.vatStatusConfirmation.file;
    }

    return this.storageService.uploadFile(file as File).pipe(
      map(
        (file): Pick<BusinessDocuments, T> =>
          ({
            [type]: {
              ...(form as any)[type],
              file,
            },
          }) as Pick<BusinessDocuments, T>,
      ),
      switchMap((document: Pick<BusinessDocuments, T>) => {
        return location === 'app'
          ? this.sendDocument(document)
          : this.authService.registerDocuments(document);
      }),
    );
  }

  private sendDocument(form: BusinessDocumentValue): Observable<void> {
    return this.post('/business/documents', form);
  }
}
