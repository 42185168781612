import { Routes } from '@angular/router';
import { AuthGuardAccess } from './common/enums/auth-guard-access';
import { authGuard } from './common/guards/auth.guard';
import { joinGuard } from './common/guards/join.guard';
import { registerEmailGuard } from './common/guards/register-email.guard';
import { resetPasswordGuard } from './common/guards/reset-password.guard';

export enum AuthRoute {
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  SUPPORT = '/support',
  JOIN = '/join',
  JOIN_SUCCESS = '/join/success',
  REGISTER = '/register',
  REGISTER_EMAIL = '/register/email',
  REGISTER_COMPANY = '/register/company',
  REGISTER_DOCUMENTS = '/register/documents',
  REGISTER_DOCUMENTS_SUCCESS = '/register/documents/success',
}

export const AUTH_ROUTES: Routes = [
  {
    path: '',
    loadComponent: () =>
      import(
        './common/components/auth-interface/auth-interface.component'
      ).then((m) => m.AuthInterfaceComponent),
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
      },
      {
        path: 'login',
        canActivate: [authGuard(AuthGuardAccess.PUBLIC)],
        loadComponent: () =>
          import('./login/login.component').then((m) => m.LoginComponent),
      },
      {
        path: 'register',
        children: [
          {
            path: '',
            pathMatch: 'full',
            canActivate: [authGuard(AuthGuardAccess.PUBLIC)],
            loadComponent: () =>
              import('./register/register.component').then(
                (m) => m.RegisterComponent,
              ),
          },
          {
            path: 'email',
            canActivate: [
              authGuard(AuthGuardAccess.PUBLIC),
              registerEmailGuard,
            ],
            loadComponent: () =>
              import('./register/register-email/register-email.component').then(
                (m) => m.RegisterEmailComponent,
              ),
          },
          {
            path: 'company',
            canActivate: [authGuard(AuthGuardAccess.REGISTER_COMPANY)],
            loadComponent: () =>
              import('./register-company/register-company.component').then(
                (m) => m.RegisterCompanyComponent,
              ),
          },
          {
            path: 'documents',
            children: [
              {
                path: '',
                pathMatch: 'full',
                canActivate: [authGuard(AuthGuardAccess.REGISTER_DOCUMENTS)],
                loadComponent: () =>
                  import(
                    './register-documents/register-documents.component'
                  ).then((m) => m.RegisterDocumentsComponent),
                children: [],
              },
              {
                path: 'success',
                canActivate: [authGuard(AuthGuardAccess.PUBLIC)],
                loadComponent: () =>
                  import('./success/success.component').then(
                    (m) => m.SuccessComponent,
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'support',
        canActivate: [authGuard(AuthGuardAccess.PUBLIC)],
        loadComponent: () =>
          import('./support/support.component').then((m) => m.SupportComponent),
      },
      {
        path: 'join',
        children: [
          {
            path: '',
            pathMatch: 'full',
            canActivate: [authGuard(AuthGuardAccess.PUBLIC), joinGuard],
            loadComponent: () =>
              import('./join/join.component').then((m) => m.JoinComponent),
          },
          {
            path: 'success',
            canActivate: [authGuard(AuthGuardAccess.PUBLIC)],
            loadComponent: () =>
              import('./join/join-success/join-success.component').then(
                (m) => m.JoinSuccessComponent,
              ),
          },
        ],
      },
      {
        path: 'forgot-password',
        canActivate: [authGuard(AuthGuardAccess.PUBLIC)],
        loadComponent: () =>
          import('./forgot-password/forgot-password.component').then(
            (m) => m.ForgotPasswordComponent,
          ),
      },
      {
        path: 'reset-password',
        canActivate: [authGuard(AuthGuardAccess.PUBLIC), resetPasswordGuard],
        loadComponent: () =>
          import('./reset-password/reset-password.component').then(
            (m) => m.ResetPasswordComponent,
          ),
      },
    ],
  },
] as const;
