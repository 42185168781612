import { Injectable } from '@angular/core';
import {
  OnyxToastOverride,
  OnyxToastService,
  OnyxToastType,
} from '@onyx/angular';
import {
  cloneDeepWith,
  isArray,
  isEmpty,
  isObject,
  isPlainObject,
} from 'lodash';
import { DateTime, Duration } from 'luxon';

interface CacheItem<T> {
  value: T;
  expiry: string;
}

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  constructor(private toastService: OnyxToastService) {
    this.clean();
  }

  public get<T>(key: string, toastOverride?: OnyxToastOverride): T | null {
    const item: CacheItem<T> | null = JSON.parse(localStorage.getItem(key)!);
    if (item == null) return null;

    const expired = DateTime.fromISO(item.expiry) < DateTime.utc();
    if (expired) {
      this.delete(key);
      return null;
    }

    this.toastService.showCustom(
      OnyxToastType.FORM_CHANGES_RESTORED,
      toastOverride,
    );
    return item.value;
  }

  public set<T>(
    key: string,
    value: T,
    ttl = Duration.fromObject({ minutes: 60 }),
  ): void {
    const cacheValue = cloneDeepWith(value, (value) => {
      const isEmptyOrFile = (value: unknown): boolean => {
        return (
          value instanceof File || (isPlainObject(value) && isEmpty(value))
        );
      };

      if (isArray(value) && value.every((item) => isEmptyOrFile(item))) {
        return [];
      } else if (isEmptyOrFile(value)) {
        return null;
      }

      return undefined;
    });

    localStorage.setItem(
      key,
      JSON.stringify({
        value: cacheValue,
        expiry: DateTime.utc().plus(ttl).toISO(),
      } satisfies CacheItem<T>),
    );
  }

  public delete(key: string): void {
    localStorage.removeItem(key);
  }

  private clean(): void {
    const isCacheItem = (item: unknown): item is CacheItem<unknown> => {
      if (!isObject(item)) return false;
      return Object.hasOwn(item, 'value') && Object.hasOwn(item, 'expiry');
    };

    for (const key of Object.keys(localStorage)) {
      let item;
      try {
        const value = localStorage.getItem(key)!;
        item = JSON.parse(value);
      } catch {
        continue;
      }

      if (!isCacheItem(item)) continue;
      if (DateTime.fromISO(item.expiry) < DateTime.now()) this.delete(key);
    }
  }
}
