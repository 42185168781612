import { Routes } from '@angular/router';

export enum OrdersRoute {
  ORDERS = '/orders',
  ADD_ORDER = '/orders/add',
}

export const ORDERS_ROUTES: Routes = [
  {
    path: '',
    children: [],
  },
  {
    path: 'add',
    children: [],
  },
] as const;
