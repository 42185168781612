<onyx-modal
  type="center"
  size="s"
  [heading]="I18N + '.heading' | translate"
  [close$]="close$"
  (keydown.enter)="next()"
  (closeModal)="dialogRef.close()"
>
  <form [formGroup]="form">
    <onyx-phone-number-input
      formControlName="privatePhone"
      [label]="'labels.privatePhone' | translate"
    ></onyx-phone-number-input>

    <onyx-dropdown
      formControlName="nationality"
      [label]="'labels.nationality' | translate"
      [options]="countries$ | async"
      [search]="true"
    ></onyx-dropdown>
  </form>

  <ng-container bottomRightOptions>
    <onyx-button type="outlined" color="black" (click)="close$.next()">
      {{ "buttons.cancel" | translate }}
    </onyx-button>

    <onyx-button type="primary" color="blue" (click)="next()">
      {{ "buttons.next" | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>
