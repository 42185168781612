import { Dialog } from '@angular/cdk/dialog';
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ContractorsRoute } from '../../contractors.routes';
import { AddContractorModalComponent } from '../components/add-contractor-modal/add-contractor-modal.component';
import { ContractorsRouterStateKey } from '../enums/contractors-router-state-key';

export const addContractorGuard: CanActivateFn = () => {
  const router = inject(Router);
  const dialog = inject(Dialog);

  const state = router.getCurrentNavigation()?.extras?.state;
  const country = state?.[ContractorsRouterStateKey.COUNTRY];
  const vatId = state?.[ContractorsRouterStateKey.VAT_ID];

  if (country && vatId) return true;

  dialog.open(AddContractorModalComponent);
  return router.navigateByUrl(ContractorsRoute.CONTRACTORS_LIST);
};
