import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, tap } from 'rxjs';
import { ApiService } from '../../../../../common/services/api.service';
import { BaseFormDto } from '../../base-form/base-form.component';
import { Base } from '../interfaces/base';
import { BASES_MOCK } from '../mocks/bases.mock';

@Injectable({
  providedIn: 'root',
})
export class BasesService extends ApiService {
  private _reload$ = new Subject<void>();
  public get reload$() {
    return this._reload$.asObservable();
  }

  constructor(protected override http: HttpClient) {
    super(http);
  }

  public listBases(): Observable<Base[]> {
    // return this.get<BaseDto[]>('/bases');
    return of(BASES_MOCK);
  }

  public getBase(_reload$: string): Observable<Base> {
    // return this.get<Base>(`/bases/${uuid}`);
    return of(BASES_MOCK[0]);
  }

  public addBase(dto: BaseFormDto): Observable<void> {
    return this.post<void>('/bases', dto).pipe(tap(() => this._reload$.next()));
  }

  public editBase(uuid: string, dto: BaseFormDto): Observable<void> {
    return this.put<void>(`/bases/${uuid}`, dto).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public deleteBase(uuid: string): Observable<void> {
    return this.delete<void>(`/bases/${uuid}`).pipe(
      tap(() => this._reload$.next()),
    );
  }
}
